import React from "react";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions05 = () => {
    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>個人資料蒐集處理利用同意書</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>一.本人(被保險人、要保人)授權保特保險經紀人股份有限公司使用本人提供之資料於保險一般行政、業務及提供予委託處理相關服務之機構。</p>
                            <p>二. 同意貴公司得將本人之資料向主管機關委託之專業機構查詢。</p>
                            <p>三. 本人（被保險人）同意貴公司得蒐集、處理及利用本人相關之健康檢查、醫療及病歷個人資料。</p>
                            <p>四. 本人知悉貴公司得依「個人資料保護法」之相關規定，於特定目的範圍內對要保人或被保險人之個人資料，有蒐集、處理及利用之權利。</p>
                            <p>五. 本人已審閱並瞭解貴公司所提供之「投保須知」，本人已瞭解貴公司蒐集、處理及利用本人個人資料之目的及用途。</p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions05;
